<template>
  <!-- 商城广告添加 -->
  <moe-page title="商城广告添加">
    <moe-form ref="bannerForm" :model="banner" :rules="bannerRules" :showBack="false" :showClose="true">
      <el-form-item label="广告图" prop="coverUrl">
        <moe-upload-file
          v-model="banner.coverUrl"
          ossKey="SHOP_MEDIA"
          :default-file-list="coverList"
          type="image"
          upload-text="上传图片"
          :getFileRatio="true"
          :ratio.sync="banner.ratio"
          @change="() => $refs.bannerForm.validateField('coverUrl')" />
      </el-form-item>

      <el-form-item label="广告位置" prop="type">
        <moe-select v-model="banner.type" type="bannerType" placeholder="请选择广告位置"></moe-select>
      </el-form-item>

      <el-form-item label="跳转类型" prop="form">
        <moe-select v-model="banner.form" type="bannerFormType" placeholder="请选择跳转类型" @change="changeSelect('form')"></moe-select>
      </el-form-item>

      <el-form-item label="外部跳转链接" prop="link" v-if="banner.form === 1">
        <el-input v-model="banner.link" placeholder="请输入外部跳转链接" clearable></el-input>
      </el-form-item>

      <template v-if="banner.form === 2">
        <el-form-item label="内部跳转地址" prop="link">
          <moe-select type="advertLink" v-model="banner.link" placeholder="请选择内部跳转地址" @change="changeSelect('link')"></moe-select>
        </el-form-item>

        <el-form-item v-if="$moe_data.navigateToIsValidator.includes(banner.link)" label="编号" prop="activityId">
          <el-input v-model="banner.activityId" placeholder="请输入编号" clearable></el-input>
        </el-form-item>
      </template>

      <!-- <el-form-item label="商品编号" prop="link" v-if="banner.form === 3">
        <el-input type="number" v-model.trim="banner.link" placeholder="请输入商品编号" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="商品专区编号" prop="link" v-if="banner.form === 4">
        <el-input type="number" v-model.trim="banner.link" placeholder="请输入商品专区编号" maxlength="50" clearable />
      </el-form-item> -->

      <el-form-item label="排序" prop="sort">
        <el-input :value="banner.sort" @input="(value) => banner.sort = $moe_formatter.formatterSort(value)" placeholder="请输入广告排序，数值越大排序越靠前" maxlength="50" clearable />
      </el-form-item>

      <el-form-item label="展示时间">
        <el-date-picker
          style="width: 100%;"
          placement="bottom-start"
          v-model="datetime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="(datetime) => ([banner.startTime, banner.endTime] = datetime || ['', ''])" />
          <div class="color-danger">（广告展示时间，时间结束广告自动关闭，如未选时间则一直存在，需手动关闭）</div>
      </el-form-item>

      <template slot="tool">
        <el-button type="primary" icon="el-icon-upload2" :loading="bannerLoad" @click="submit()">{{ bannerLoad ? '提交中' : '提交信息' }}</el-button>
      </template>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'GoodsAdvertAdd',
  computed: {
    IS_EDIT() {
      if (this.banner.id) {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    const banner = {
      id: '',
      coverUrl: '', //图片地址
      ratio: [],
      sort: '',     //排序
      type: 1,      //位置：1.首页轮播图
      form: 1,      //跳转类型 1：商品 2：活动页
      link: '',     //链接地址或者商品ID
      activityId: '',//本地储存字段,不提交后台
      startTime: '',
      endTime: '',
    };

    //数据校验
    const verify = this.$moe_verify.verifyForm;
    const checkForm = (rule, value, callback) => {
      if (!value) {
        if (value === 0) {
          callback();
        } else {
          return callback(new Error('请选择跳转类型'));
        }
      } else {
        if ([3, 4].includes(value)) {
          return callback(new Error(`请重新选择跳转类型`));
        } else {
          callback();
        }
      }
    }
    const checkLink = (rule, value, callback) => {
      if (!value) {
        const { form } = this.banner;
        if (form === 1) {
          return callback(new Error(`请输入外部跳转链接`));
        } else if (form === 2) {
          return callback(new Error(`请选择内部跳转地址`));
        }
        // else if (form === 3) {
        //   return callback(new Error(`请输入商品编号`));
        // } else if (form === 4) {
        //   return callback(new Error(`请输入商品专区编号`));
        // }
      } else {
        callback();
      }
    }
    const bannerRules = {
      coverUrl: verify.isEmpty('请上传广告图片'),
      type: verify.isEmpty('请选择广告位置'),
      form: [{ required: true, validator: checkForm, trigger: ['blur', 'change'] }],
      link: [{ required: true, validator: checkLink, trigger: ['blur', 'change'] }],
      activityId: verify.isEmpty('请输入编号'),
    };

    return {
      banner,
      datetime: [],
      bannerRules,
      coverList: [],
      bannerLoad: false, //提交状态
    };
  },
  methods: {
    changeSelect(source) {
      switch (source) {
        case 'form':
          this.banner.link = '';
          this.banner.activityId = '';
          break;
        case 'link':
          this.banner.activityId = '';
          break;
      }
    },
    submit() {
      this.$refs['bannerForm'].validate(() => {
        this.bannerLoad = true;
        let params = this.$moe_lodash.cloneDeepData({
          ...this.banner,
          ratio: JSON.stringify(this.banner.ratio),
        })

        if (this.$moe_data.navigateToIsValidator.includes(params.link)) {
          params.link = `${params.link}?id=${params.activityId}`;
          delete params.activityId;
        } else {
          delete params.activityId;
        }

        if (this.IS_EDIT) {
          this.req_bannerAmend(params);
        } else {
          this.req_bannerAdd(params);
        }
      });
    },
    req_bannerAdd(params) {
      this.$moe_api.BANNER_API.bannerAdd(params).then((data) => {
        if (data.code == 200) {
          this.$moe_msg.success('添加成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message)
        }
      }).finally(() => {
        this.bannerLoad = false;
      })
    },
    req_bannerAmend(params) {
      this.$moe_api.BANNER_API.bannerAmend(params).then((data) => {
        if (data.code == 200) {
          this.$moe_msg.success('修改成功', {
            completionHandler: () => {
              this.$moe_coordinator.navigateBack();
            }
          });
        } else {
          this.$moe_msg.error(data.message);
        }
      }).finally(() => {
        this.bannerLoad = false;
      });
    },
    req_bannerDetail() {
      if (this.$route.query.id) {
        this.$moe_api.BANNER_API.bannerDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code == 200) {
            let { id, coverUrl, sort, type, form, startTime, endTime, link, status, ratio } = data.result;
            let newLink = '', activityId = '';
            if (form === 2 && link.indexOf('?') > -1 && this.$moe_data.navigateToIsValidator.includes(link.split('?')[0])) { // N元任选活动
              newLink = link.split('?')[0]
              activityId = this.$moe_formatter.getUrlParams(link).id;
            } else {
              newLink = link
            }
            this.banner = {
              id,
              coverUrl: this.$moe_yunHelper.formatterImageUrl(coverUrl),
              sort,
              type,
              form,
              startTime,
              endTime,
              link: newLink,
              activityId,
              status,
              ratio: ratio ? JSON.parse(ratio) : []
            };
            if (startTime) {
              this.datetime = [startTime, endTime];
            }
            this.coverList = [{
              url: coverUrl,
              tempFilePath: this.$moe_yunHelper.getTempFilePath(coverUrl),
            }]
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      }
    }
  },
  mounted() {
    this.req_bannerDetail();
  }
};
</script>
